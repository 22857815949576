// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap/dist/js/bootstrap');
require('select2/dist/js/select2.js');
require("@nathanvda/cocoon")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
$(document).on('turbolinks:load', function () {
  // remove start
  $('.role').on('click', function () {
    $(this).toggleClass('selected');
  });
  // remove end
  $('.select_location').on('change', function () {
    window.location = '/select_location?lid=' + this.value;
  });
  // keep left nav toggle state
  var nav_toggleed = Cookies.get('nav-minimize');
  nav_toggleed = nav_toggleed == 'true';
  console.log(nav_toggleed);
  $('body').toggleClass('nav-minimize', nav_toggleed);
  // navbar expand on mobile
  if ($(window).width() <= 576) {
    $('body').addClass('nav-minimize');
  }
  // if clicked on minimize main nav icon on  header
  $('.top-navbar').on('click', '.aside-toggler', function () {
    toggleNavbar(nav_toggleed);
  });
  $('.select2-multiple').select2({
    multiple: true,
    // theme: 'bootstrap4',
  });
});
function toggleNavbar(nav_toggleed) {
  //var $this = $(this);
  nav_toggleed = !nav_toggleed;
  $('body').toggleClass('nav-minimize', nav_toggleed);
  Cookies.set('nav-minimize', nav_toggleed);
  return false;
}

require("trix")
require("@rails/actiontext")
